import React from "react";
import useSignature from "../hooks/useSignature";
import SignatureCanvas from "react-signature-canvas";

const JudgeSignNew = ({ setClose, propState }) => {
  const { signCanvasRef, saveSignature, clearSignature } = useSignature();

  const handleSave = () => {
    if (!signCanvasRef.current.isEmpty()) {
      propState.setState(() => ({
        ...propState.state,
        judgeSignature: saveSignature(),
      }));
    }
    setClose();
  };

  return (
    <div className="flex w-full h-full flex-col justify-start items-center gap-y-2">
      <div className="flex bg-yellow-50 w-96 h-96 border-4 border-dashed">
        <SignatureCanvas
          ref={signCanvasRef}
          minWidth={3} // 최소 선 두께
          maxWidth={5} // 최대 선 두께
          canvasProps={{ style: { width: "100%", height: "100%" } }}
        />
      </div>
      <div className="flex w-full gap-x-2 px-1">
        <button
          className="w-1/3 h-10 bg-blue-800 text-gray-100"
          onClick={handleSave}
        >
          저장
        </button>
        <button className="w-1/3 h-10 bg-blue-300" onClick={clearSignature}>
          초기화
        </button>
        <button className="w-1/3 h-10 bg-gray-200" onClick={setClose}>
          취소
        </button>
      </div>
    </div>
  );
};

export default JudgeSignNew;
